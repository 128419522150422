<template>
  <div>
    <el-table v-bind="$props" :data="tableData" stripe style="width: 100%">
      <template v-for="(i, name) in $scopedSlots" :slot="name" slot-scope="{ row, column,index }">
        <slot :name="name" v-bind="{ row, column , index }" />
      </template>
    </el-table>
    <div class="page-menus" v-if="page">
      <el-pagination @current-change="handleCurrentChange" :current-page="pageNum"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Table } from 'element-ui';
export default {
  name: 'AcquisitionsystemTableModule',
  props: {
    ...Table.props,
    tableData: {
      type: Array
    },
    page: {
      type: Boolean
    },
    total: {
      type: [Number, String]
    },
    pageNum: {
      type: [Number, String]
    }
  },
  data () {
    return {
      // stripe: true,
    };
  },

  mounted () {

  },

  methods: {
    handleCurrentChange (val) {
      this.$emit('pageChange', val)
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table th.el-table__cell {
  background: #EBF0FF;
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F7FAFF;
}

::v-deep .el-table td.el-table__cell {
  border: none;
  // height: 60px;
  // padding: 0;
}

.page-menus {
  text-align: right;
  margin-top: 20px;
}
</style>
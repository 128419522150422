/**
 * 文件描述: 角色管理的api文档
 * @author 阿苏
 * @dTime  2022/09/17
 * @see    
 */
import feach from "../axios";
const APIS = {
  RoleList: "/role/list", // 用户列表
  SelRoleList: "/role/choiceList", // 角色下啦列表
  MenusList: "/menu/list", // 页面菜单列表
  AddRole: "/role/add",// 添加角色
  DeleteRole: "/role/del", // 删除角色
  RoleState: "/role/state", // 修改角色状态
  EditRole: "/role/update", // 修改角色信息
}
/**
 * @description 获取用户列表
 * @param 
 */
export const getRoleList = params => {
  return feach.request({
    url: APIS.RoleList,
    method: "GET",
    params
  });
};
/**
 * @description 角色下啦列表
 * @param 
 */
export const getSelRoleList = params => {
  return feach.request({
    url: APIS.SelRoleList,
    method: "GET",
    params
  });
};
/**
 * @description 角色下啦列表
 * @param 
 */
export const getMenusList = params => {
  return feach.request({
    url: APIS.MenusList,
    method: "GET",
    params
  });
};
/**
 * @description 新建角色
 * @param 
 */
export const newAddRole = data => {
  return feach.request({
    url: APIS.AddRole,
    method: "post",
    data
  });
};

/**
 * @description 删除角色
 * @param 
 */
export const deleteRole = params => {
  return feach.request({
    url: `${APIS.DeleteRole}/${params.roleId}`,
    method: "DELETE",
  });
};
/**
 * @description 删除角色
 * @param 
 */
export const editRoleState = params => {
  return feach.request({
    url: `${APIS.RoleState}/${params.roleId}/${params.state}`,
    method: "PUT",
  });
};
/**
 * @description 删除角色
 * @param 
 */
export const EditRole = data => {
  return feach.request({
    url: `${APIS.EditRole}/${data.roleId}`,
    method: "PUT",
    data
  });
};

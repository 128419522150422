
/**
 * 文件描述: 管理员API接口
 * @author 阿苏
 * @dTime  2022/09/15
 * @see    
 */
import feach from "../axios";
const APIS = {
    StaffList: "/staff/list", // 用户列表
    StaffState: "/staff/state", // 修改用户状态
    StaffRole: '/staff/role', // 修改用户部门
}
/**
 * @description 获取用户列表
 * @param 
 */
export const getStaffList = params => {
    return feach.request({
        url: APIS.StaffList,
        method: "GET",
        params
    });
};
/**
 * @description 修改用户状态
 * @param 
 */
export const editStaffState = params => {
    return feach.request({
        url: `${APIS.StaffState}/${params.staffId}/${params.state}`,
        method: "PUT",
    });
};
/**
 * @description 修改用户角色
 * @param 
 */
 export const editStaffRole = params => {
    return feach.request({
        url: `${APIS.StaffRole}/${params.staffId}/${params.roleId}`,
        method: "POST",
    });
};
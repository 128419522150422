<!-- 文件描述: 权限管理-管理员列表 -->
<!-- 创建时间: 2022/09/14；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/09/14；最后修改人: 阿苏-->
<template>
  <div class="task-list">
    <div class="active">
      <div class="tag-box">
        <div class="tag-item sign">
          管理员列表
        </div>
        <div class="tag-item">
          角色权限
        </div>
      </div>
      <!-- <el-button type="primary" class="add-taskp-btn" @click="onSkitNewAdd">添加管理员</el-button> -->
    </div>
    <div class="content">
      <TableModule :tableData="tableData" :page="true" :total="total" @pageChange="pageChange">
        <el-table-column prop="staffName" label="管理员名称">
        </el-table-column>
        <el-table-column prop="roleName" label="角色">
        </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.switcState" @change="editState(scope.row)" active-color="#155BD4"
              inactive-color="#C8C9CC">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editStaffSole(scope.row)">编辑</el-button>
            <el-button type="text">删除</el-button>
          </template>
        </el-table-column>
      </TableModule>
    </div>
    <el-dialog title="修改角色" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-select v-model="selRole" placeholder="请选择角色">
        <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableModule from "../../components/Table/TableModule.vue"
import * as Apis from '../../http/api/staff'
import { getSelRoleList } from '../../http/api/role'
export default {
  name: 'AcquisitionsystemList',
  components: {
    TableModule
  },
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      pages: 1,
      total: 0,
      roleList: [], // 角色列表
      selRole: "", // 选中要更换的角色
      selStaff: "", // 选择要修改的用户
    };
  },
  created () {
    this.stafflist()
    this.getRoleList()
  },
  mounted () {
  },
  methods: {
    /**
     * @description 获取用户列表
     */
    async stafflist () {
      try {
        let { code, body } = await Apis.getStaffList({
          pages: this.pages,
          rows: 10
        })
        if (code == 200) {

          this.tableData = body.content.map(res => {
            return {
              ...res,
              switcState: res.state == 1 ? true : false
            }
          });
          console.log(this.tableData);
          this.total = body.total;
        }
      } catch (error) {
        console.error('stafflist', error);
      }
    },
    /**
     * @description 修改用户状态
     * @param 
     */
    async editState (item) {
      console.log();
      try {
        let { code } = await Apis.editStaffState({
          staffId: item.staffId,
          state: item.switcState ? 1 : 0
        })
        if (code == 200) {
          this.$message({
            message: '状态修改成功!',
            type: 'success'
          });
          this.stafflist()
        }
      } catch (error) {
        console.error('editState', error);
      }
    },
    /**
     * @description 切换分页
     * @param 
     */
    pageChange (val) {
      this.pages = val
      this.stafflist()
    },
    /**
     * @description 修改用户的角色
     * @param 
     */
    editStaffSole ({ staffId }) {
      this.dialogVisible = true
      this.selStaff = staffId
      this.selRole = ""
    },
    /**
     * @description 关闭修改弹框
     * @param 
     */
    handleClose () {
      this.dialogVisible = false
      this.selStaff = ''
    },
    /**
     * @description 角色列表
     * @param 
     */
    async getRoleList () {
      try {
        let { code, body } = await getSelRoleList()
        if (code == 200) {
          this.roleList = body
        }
      } catch (error) {
        console.error('getRoleList', error);
      }
    },
    /**
     * @description 提交修改部门
     * @param 
     */
    async submitEdit () {
      try {
        let { code } = await Apis.editStaffRole({
          staffId: this.selStaff,
          roleId: this.selRole
        })
        if (code == 200) {
          this.$message({
            message: '角色修改成功!',
            type: 'success'
          });
          this.stafflist()
          this.dialogVisible = false
          this.roleId = ''
          this.staffId = ''
        }
      } catch (error) {
        console.error('submitEdit', error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.task-list {
  padding: 30px 24px;
}

.active {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .add-taskp-btn {
    width: 100px;
    height: 36px;
    background: #507CE5;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    padding: 0;
  }

  .tag-box {
    width: 300px;
    height: 36px;
    background: #F7FAFF;
    border-radius: 18px;
    display: flex;
  }

  .sign {
    width: 150px;
    height: 36px;
    background: #507CE5;
    border-radius: 18px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
  }

  .tag-item {
    width: 150px;
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    line-height: 36px;
  }
}

.content {
  margin-top: 20px;
}
</style>